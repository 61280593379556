import "./../scss/main.scss";
import * as bootstrap from "bootstrap";
import L from "leaflet";
import GLightbox from 'glightbox';
import img_icon_cart from "./../images/icon-cart.svg";
import img_slider_image from "./../images/slider-img.png";
import img_icon_cart2 from "./../images/icon-cart2.svg";
import img_icon_envelope from "./../images/icon-envelope.svg";
import img_icon_envelope2 from "./../images/icon-envelope2.svg";
import img_dzial_lotnictwa from "./../images/dzial-lotnictwa-image.png";
import img_dzial_kolejowy from "./../images/dzial-kolejowy-img.png";
import img_accent_1 from "./../images/accent-1.svg";
import img_accent_2 from "./../images/accent-2.svg";
import img_dzial_samochodowy from "./../images/dzial-samochodowy-img.png";
import img_produkcja_maszyn from "./../images/produkcja-maszyn-img.png";
import img_remonty_maszyn from "./../images/remonty-maszyn-img.png";
import img_product_1 from "./../images/product-1.png";
import img_icon_cart3 from "./../images/icon-cart3.svg";
import img_logo_1 from "./../images/logo-1.png";
import img_logo_2 from "./../images/logo-2.png";
import img_logo_3 from "./../images/logo-3.png";
import img_logo_4 from "./../images/logo-4.png";
import video_movie from "./../images/wideo.mov";
import img_intercooler from "./../images/intercooler-img.png";
import img_icon_arrow_right from "./../images/icon-arrow_right.svg";
import img_logo from "./../images/logo.png";
import img_icon_phone from "./../images/icon-phone.svg";
import img_icon_pin from "./../images/icon-pin.svg";
import img_icon_facebook from "./../images/icon-facebook.svg";
import img_icon_youtube from "./../images/icon-youtube.svg";
import img_marker_icon2 from "./../images/marker-icon-2x.png";
import img_marker_shadow from "./../images/marker-shadow.png";
import img_dzial_lotnictwa_img1 from "./../images/dzial-lotnictwa-img1.png";
import img_dzial_lotnictwa_img2 from "./../images/dzial-lotnictwa-img2.png";
import icon_prasy from "./../images/icon-prasy.png";
import icon_spawanie from "./../images/icon-spawanie.png";
import icon_frezarki from "./../images/icon-frezarki.png";
import icon_tokarki from "./../images/icon-tokarki.png";
import icon_powierzchnia from "./../images/icon-powierzchnia.png";
import icon_wiertarko from "./../images/icon-wiertarko.png";
import icon_wytaczarki from "./../images/icon-wytaczarki.png";
import img_gallery1 from "./../images/gallery-1.png";
import img_gallery2 from "./../images/gallery-2.png";
import img_gallery3 from "./../images/gallery-3.png";
import img_gallery4 from "./../images/gallery-4.png";
import img_gallery5 from "./../images/gallery-5.png";
import img_gallery6 from "./../images/gallery-6.png";
import img_gallery7 from "./../images/gallery-7.png";
import img_gallery8 from "./../images/gallery-8.png";
import img_ofirmie_photo1 from "./../images/ofirmie-photo1.png";
import img_ofirmie_photo2 from "./../images/ofirmie-photo2.png";
import img_ofirmie_photo3 from "./../images/ofirmie-photo3.png";
import img_jakosc from "./../images/img-jakosc.png";
import img_oferty_pracy from "./../images/oferty-pracy.png";
import img_oferta_pracy from "./../images/img-oferta-pracy.png";
import icon_marker from "./../images/icon-marker.svg";
import icon_clock from "./../images/icon-clock.svg";
import icon_doc from "./../images/icon-doc.svg";
import icon_arrow_right_2 from "./../images/icon-arrow_right_2.svg";
import img_ukosowarka1 from "./../images/ukosowarka-01.jpg";
import img_ukosowarka2 from "./../images/ukosowarka-02.jpg";
import img_ukosowarka3 from "./../images/ukosowarka-03.jpg";
import img_ukosowarka4 from "./../images/ukosowarka-04.jpg";
import img_ukosowarka5 from "./../images/ukosowarka-05.jpg";
import img_ukosowarka6 from "./../images/ukosowarka-06.jpg";
import img_ukosowarka7 from "./../images/ukosowarka-07.jpg";
import img_ukosowarka8 from "./../images/ukosowarka-08.jpg";
import img_mlyn1 from "./../images/mlyn-01.jpg";
import img_mlyn2 from "./../images/mlyn-02.jpg";
import img_mlyn3 from "./../images/mlyn-03.jpg";
import img_mlyn4 from "./../images/mlyn-04.jpg";
import img_ukosowarka_baner from "./../images/ukosowarka-baner.png";
import img_icon_laser from "./../images/icon-laser.png";
import img_icon_szlifowanie from "./../images/icon-szlifowanie.png";
import img_icon_giecie from "./../images/icon-giecie.png";
import img_icon_prasa from "./../images/icon-prasa.png";

(function(){
    let contactMapElement = document.getElementById("contact-map");


    // Fixed header during scrolling
    function fixedHeaderNavbar(){
        const page_header = document.querySelector(".header");

        window.addEventListener("scroll", (event) => {
            const currentScroll = window.pageYOffset;
    
            if(currentScroll > 112){
                page_header.classList.add("header-scroll");
            } else{
                page_header.classListNaNpxove("header-scroll");
            }
        });
    }

    // Header menu
    function headerMenuDropdownClick(){
        const headerMenuItems = document.querySelectorAll(".header-nav > ul .menu-item-has-children");

        for(let i = 0; i < headerMenuItems.length; i++){
            const headerMenuItemAnchor = headerMenuItems[i].querySelector("a");
            
            headerMenuItemAnchor.addEventListener("click", (event) => {
                const subMenuElement = headerMenuItems[i].querySelector(".sub-menu");

                if(subMenuElement.style.display){
                    subMenuElement.styleNaNpxoveProperty("display");
                } else{
                    subMenuElement.style.setProperty("display", "block", "important");
                }
            });
        }
    }

    // Contact map
    function generateMap(){
        contactMapElement = L.map("contact-map").setView([50.34341, 21.47410], 15);

        // let Stadia_OSMBright = L.tileLayer("https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png", {
        //     maxZoom: 20,
        //     attribution: '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
        // });
    
        // Stadia_OSMBright.addTo(contactMapElement);

        L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
            maxZoom: 20,
            attribution: '© OpenStreetMap'
        }).addTo(contactMapElement);
    
        let marker = L.marker([50.34341, 21.47410]).addTo(contactMapElement);
    }

    function generateOfertaGallery(){
        const lightbox = GLightbox({
            selector: ".oferta_gallery",
            touchNavigation: true,
            loop: true,
            autoplayVideos: true
        });
    }

    function generateCertyfikatyGallery(){
        const lightbox = GLightbox({
            selector: ".certyfikaty_gallery",
            touchNavigation: true,
            loop: true,
            autoplayVideos: true
        });
    }

    function generateProdukcjaGallery(){
        const lightbox = GLightbox({
            selector: ".produkcja_gallery",
            touchNavigation: true,
            loop: true,
            autoplayVideos: true
        });
    }

    // function checkFileTypesOfertaPracy(){
    //     const file_input = document.getElementById('oferta_pracy_cv');
    //     const file_input_error = document.querySelector(".oferta_pracy_cv_error");
    //     let input_has_error = false;

    //     if(file_input){
    //         file_input.addEventListener('change', function() {
    //             const selected_file = this.files[0];
    //             const selected_file_size = this.files[0].size / 1024; // kB
                
    //             if (selected_file) {
    //                 let selected_file_type = selected_file.type;
    //                 if (selected_file_type !== 'application/pdf' && selected_file_type !== 'image/jpeg' && selected_file_type !== 'image/png') {
    //                     file_input_error.innerHTML = `Błędny typ pliku. Dozwolone są tylko PDF, JPG oraz PNG.`;
    //                     this.value = '';
    //                     input_has_error = true;
    //                 }

    //                 if(selected_file_size > 5120){
    //                     file_input_error.innerHTML = `Zbyt duży rozmiar. Maksymalnie 5MB.`;
    //                     this.value = '';
    //                     input_has_error = true;
    //                 }

    //                 if(!input_has_error){
    //                     file_input_error.innerHTML = ``;
    //                 }
    //             }

    //             input_has_error = false;
    //         });
    //     }
    // }

    if(contactMapElement){
        generateMap();
    }
    headerMenuDropdownClick();
    // fixedHeaderNavbar();
    generateOfertaGallery();
    generateCertyfikatyGallery();
    generateProdukcjaGallery();
    // checkFileTypesOfertaPracy();
})();